<template>
  <div class="outer">
    <div class="formBox">
      <el-form
        :inline="true"
        :model="searchFormData"
        class="demo-form-inline"
        size="mini"
      >
        <el-form-item :label="$t('label.label') + '：'">
          <el-input
            style="width: 200px"
            @change="dateChange"
            prop="workOrderNo"
            v-model="searchFormData.condition.pageLabel"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('homepage.components.creationtime') + '：'">
          <el-date-picker
            style="width: 350px"
            v-model="submitTime"
            type="datetimerange"
            align="right"
            unlink-panels
            :range-separator="$t('label.forecast.customforecast.submit.zhi')"
            :start-placeholder="$t('label.starttime')"
            :end-placeholder="$t('label.endtime')"
            value-format="yyyy-MM-dd HH:mm:ss"
            @change="dateChange"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <el-button type="primary" size="mini" @click="createBtn">{{
        $t("label.customsetting.manage")
      }}</el-button>
    </div>

    <!-- 表格数据 -->
    <div class="cusTable">
      <dynamic-table
        class="myTable"
        :height="tableHeight"
        :tableHeader="tableData.title"
        :table-data="tableData.data"
        @loadScroll="loadScroll"
        :loading="loading"
        ref="dynamicTable"
        :isShowOpration="true"
        :oprationBtnList="oprationBtnList"
        @del="del"
        :isClick="clickFlag"
      ></dynamic-table>
    </div>
    <!--    保存弹框-->
    <customPageDialog
      ref="customPageDialog"
      @refresh="refresh"
    ></customPageDialog>
  </div>
</template>

<script>
import dynamicTable from "../Table/v-dynamicTable";
import customPageDialog from "../customPageDialog/index";
import dayjs from "dayjs";
export default {
  components: {
    dynamicTable,
    customPageDialog,
  },
  data() {
    return {
      tableData: {
        data: [],
        title: [],
      },
      oprationBtnList: [
        {
          action: "del",
          label: this.$i18n.t("pagecreator_page_button_delete"),
        },
      ],
      clickFlag: true,
      tableHeight: "", //table的高度
      submitTime: [],
      // 查询条件参数
      searchFormData: {
        pageNo: 1,
        pageSize: 20,
        condition: {
          pageLabel: "", // 关键字
          dtBegin: "", // 开始时间
          dtEnd: "", // 结束时间
        },
      },
      indexPage: 0, //页数++
      loading: false,
      refreshDate: "",
      devWebDomain: "",
    };
  },
  mounted() {
    this.getTableData();
    this.getCustomDomain();
  },
  methods: {
    /**
     * 自定义页面-获取devconsoleWeb域名
     */
    getCustomDomain() {
      this.$Axios
        .post(window.Glod["ccex-sysconfig"] + "/auth/domain/getBackEndAddr", {
          accessToken: this.$CCDK.CCToken.getToken(),
        })
        .then((res) => {
          if (res.data) {
            this.devWebDomain = res.data.data.devconsoleWebAddress;
          }
        });
    },

    getTableData() {
      this.loading = true;
      this.$devHttp
        .postFormat(
          `${window.Glod["ccex-dev"]}/custom/pc/1.0/post/pageCustomPage`,
          this.searchFormData
        )
        .then(
          (res) => {
            if (res.returnCode == 200) {
              if (res.data.title.length != 0) {
                this.tableData.title = res.data.title.map((item) => {
                  item.title = this.$i18n.t(item.title);
                  return item;
                });
              }
              if (res.data.list.length != 0) {
                // 处理日期时间
                res.data.list.forEach((item) => {
                  item.createDate = dayjs(item.createDate).format(
                    "YYYY-MM-DD HH:mm:ss"
                  );
                  item.lastModifyDate = dayjs(item.lastModifyDate).format(
                    "YYYY-MM-DD HH:mm:ss"
                  );
                  if (item.isTemplate == 1) {
                    // 是
                    item.isTemplate = this.$i18n.t("label.tabpage.yesz");
                  } else {
                    // 否
                    item.isTemplate = this.$i18n.t("label.tabpage.noz");
                  }
                });
              }
              this.tableData.data = this.tableData.data.concat(res.data.list); //赋值表格数据
              this.loading = false;
            }
          },
          (err) => {
            this.$message({
              message: err,
              type: "warning",
            });
            this.loading = false;
          }
        );
    },
    //条件变化时
    dateChange() {
      // 初始化数据
      this.tableData = {
        data: [],
        title: [],
      };
      this.indexPage = 0;
      this.searchFormData.pageNo = 1;
      if (this.submitTime != null) {
        this.searchFormData.condition.dtBegin = this.submitTime[0];
        this.searchFormData.condition.dtEnd = this.submitTime[1];
      } else {
        this.searchFormData.condition.dtBegin = "";
        this.searchFormData.condition.dtEnd = "";
      }
      this.getTableData();
    },
    // 滚动加载
    loadScroll() {
      if (this.indexPage > 0) {
        let currentDate = dayjs();
        if (currentDate - this.refreshDate > 1000) {
          this.indexPage++;
          this.searchFormData.pageNo = this.indexPage;
          this.getTableData();
          this.refreshDate = currentDate;
        }
      } else {
        this.indexPage++;
      }
    },
    // 管理
    createBtn() {
      let devWeb = this.devWebDomain
        ? this.devWebDomain
        : "https://devconsole.cloudcc.com/";
      // 公有云需要判断是狗有菜单权限
      if (window.Glod.CDN_URL != ".") {
        this.$devHttp
          .postFormat(
            `${window.Glod["ccex-sysconfig"]}/auth/pc/1.0/get/getUserInfo`,
            {
              accessToken: this.$CCDK.CCToken.getToken(),
            }
          )
          .then((res) => {
            if (res.returnCode == 200 && res.data != null) {
              if (Object.keys(res.data.function).length == 0) {
                this.$message.warning(
                  "权限不足，请联系系统管理员；在多组织配置库用户表信息下配置角色，角色ID：role2021B454B8CDBrsfsq"
                );
              } else {
                // 有菜单权限跳转到devconsole项目
                window.open(
                  devWeb +
                    `#/pageManagement/createCustomPage?accessToken=${
                      this.$CCDK.CCToken.getToken() + "," + this.$i18n.locale
                    }`
                );
              }
            } else {
              this.$message({
                message: res.returnInfo,
                type: "warning",
              });
            }
          })
          .catch((error) => {
            this.$message({
              message: error,
              type: "warning",
            });
          });
      } else {
        // 私有云直接跳转
        window.open(
          devWeb +
            `#/pageManagement/createCustomPage?accessToken=${
              this.$CCDK.CCToken.getToken() + "," + this.$i18n.locale
            }`
        );
      }
    },
    // 删除
    del(row) {
      this.$confirm(
        this.$i18n.t("label.salescloud.salescloud.continuerecover"),
        this.$i18n.t("label_popup_infoz"),
        {
          confirmButtonText: this.$i18n.t("label.ems.confirm"),
          cancelButtonText: this.$i18n.t("pagecreator_button_cancel"),
          type: "warning",
        }
      )
        .then(() => {
          this.$devHttp
            .postFormat(
              `${window.Glod["ccex-dev"]}/custom/pc/1.0/post/deleteCustomPage`,
              {
                id: row.id,
              }
            )
            .then(
              (res) => {
                if (res.returnCode == 200) {
                  this.$message.success(
                    this.$i18n.t("label.ems.delete.success")
                  );
                  this.tableData = {
                    data: [],
                    title: [],
                  };
                  this.getTableData();
                }
              },
              (err) => {
                this.$message.warning(err);
              }
            );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: this.$i18n.t("label.delete.cancel"),
          });
        });
    },
    //行内点击
    // handleClick(row) {
    //   this.$store.commit("setCustomPageAction", "edit");
    //   this.$router.push({
    //     path: `/pageManagement/createCustomPage`,
    //     query: {
    //       id: row.id,
    //     },
    //   });
    // },
    refresh() {
      this.getTableData();
    },
  },
};
</script>
<style lang="scss" scoped >
.outer {
  height: calc(100vh - 50px - 60px);
  width: 100%;
  position: relative;
  padding: 1vh;
  .formBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    height: 6vh;
    padding: 1vh;
    margin-bottom: 1vh;
    border-radius: 4px;
  }
  .demo-form-inline {
    display: flex;
  }
  .cusTable {
    height: calc(100vh - 50px - 9vh - 5vh);
  }
  .myTable {
    border-radius: 4px;
  }
}
</style>
<style scoped>
.demo-form-inline ::v-deep .el-form-item__label {
  font-size: 12px;
}
.demo-form-inline ::v-deep .el-form-item--mini.el-form-item,
.el-form-item--small.el-form-item {
  display: flex;
  align-items: center;
  justify-content: center;
}
.el-form-item {
  margin-bottom: 0;
}
</style>
