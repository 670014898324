<template>
  <div class="mini_program_body">
    <custom-page></custom-page>
  </div>
</template>

<script>
import customPage from "./components/customPage/index";
export default {
  data() {
    return {
      
    };
  },
  created() {},
  methods: {},
  components: {
    customPage,
  },
};
</script>