<template>
  <div class="dialogWrapper">
    <!-- 添加表单 -->
    <el-dialog :title="title" :visible.sync="dialogFormVisible">
      <el-form
        :model="formDataList"
        size="mini"
        :inline="true"
        class="dialogForm-dialogForm"
      >
        <el-form-item label="页面标签">
          <el-input
            v-model="formDataList.pageLabel"
            autocomplete="off"
            :disabled="customPageAction == 'edit' ? true : false"
          ></el-input>
        </el-form-item>
        <el-form-item label="API">
          <el-input
            v-model="formDataList.pageApi"
            autocomplete="off"
            :disabled="customPageAction == 'edit' ? true : false"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="mini" @click="dialogFormVisible = false">{{
          $t("pagecreator_button_cancel")
        }}</el-button>
        <el-button size="mini" type="primary" @click="save">{{
          $t("label.save")
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from "vuex";
import httpConfig from "@/config/httpConfig";
export default {
  props: {
    title: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      dialogFormVisible: false, //添加表单
      formDataList: {
        id: "",
        pageLabel: "",
        pageApi: "",
        pageContent: "",
        // version: 1.0,
        orgId: "",
        renderVersion: "",
        compList: [],
      },
      componentData: {},
      canvasStyleData: {},
      customPageRowData: {}, // 自定义页面每条数据
    };
  },
  computed: mapState(["customPageAction"]),
  methods: {
    // 打开弹框
    
    open(componentData, canvasStyleData, customPageRowData) {
      this.dialogFormVisible = true;
      this.componentData = componentData;
      this.canvasStyleData = canvasStyleData;
      this.customPageRowData = customPageRowData;
      this.formDataList.pageLabel = this.customPageRowData.pageLabel;
      this.formDataList.pageApi = this.customPageRowData.pageApi;
    },
    // 保存
    save() {
      this.componentData.forEach((item) => {
        this.formDataList.compList.push({
          id: item.comId,
          compName: item.name,
        });
      });
      this.formDataList.pageContent = JSON.stringify(this.componentData);
      this.formDataList.canvasStyleData = JSON.stringify(this.canvasStyleData);
      if (this.$store.state.customPageAction == "create") {
        // 新建
        httpConfig
          .post(
            "/devconsole/custom/pc/1.0/post/insertCustomPage",
            this.formDataList
          )
          .then(
            (res) => {
              if (res.returnCode == 200) {
                this.$message({
                  message: res.returnInfo,
                  type: "success",
                });
                this.dialogFormVisible = false;
                this.$router.push(`/pageManagement/customPage`);
              }
            },
            (err) => {
              this.$message({
                message: err,
                type: "warning",
              });
            }
          );
      } else if (this.$store.state.customPageAction == "edit") {
        // 编辑修改
        this.formDataList.id = this.customPageRowData.id;
        // this.formDataList.version = this.customPageRowData.version;
        this.formDataList.renderVersion = this.customPageRowData.renderVersion;
        httpConfig
          .post(
            "/devconsole/custom/pc/1.0/post/insertCustomPage",
            this.formDataList
          )
          .then(
            (res) => {
              if (res.returnCode == 200) {
                this.$message({
                  message: res.returnInfo,
                  type: "success",
                });
                this.dialogFormVisible = false;
                this.$router.push(`/pageManagement/customPage`);
              }
            },
            (err) => {
              this.$message({
                message: err,
                type: "warning",
              });
            }
          );
      } else {
        // copy
        let params = {
          id: this.customPageRowData.id,
          pageLabel: this.formDataList.pageLabel,
          pageApi: this.formDataList.pageApi,
        };
        httpConfig
          .post("/devconsole/custom/pc/1.0/post/copyCustomPage", params)
          .then(
            (res) => {
              if (res.returnCode == 200) {
                this.$message({
                  message: res.returnInfo,
                  type: "success",
                });
                this.dialogFormVisible = false;
                this.$emit("refresh");
              }
            },
            (err) => {
              this.$message({
                message: err,
                type: "warning",
              });
            }
          );
      }
    },
  },
};
</script>
<style lang="scss">
.dialogWrapper {
  .dialogForm-dialogForm .el-form-item__label {
    width: 110px;
    font-size: 12px;
    font-weight: normal;
  }
  .el-dialog__title {
    font-size: 14px;
  }
  .el-dialog {
    width: 25%;
  }
}
</style>
